import { PageProps } from "gatsby"
import Login from "../components/Login"
import * as React from "react"
import Seo from "../components/SEO"


const LoginPage: React.FC<PageProps<any, any, { email: string }>> = ({
  location,
}) => {
  return (
    <>
      <Seo title="Login" />

      <Login.Primary email={location?.state?.email} />
      <Login.Mobile email={location?.state?.email} />
    </>
  )
}

export default LoginPage
