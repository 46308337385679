import { Link, navigate } from "gatsby"
import { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"
import { isBrowser } from "../../helpers"
import useAuth, { AuthContext } from "../../hooks/useAuth"
import ButtonGroup from "../Buttons/ButtonGroup"
import ErrorNotice from "../ErrorNotice/ErrorNotice"
import { UpArrow } from "../Icons/UpArrow"
import Modal from "../Modal"

const Form: React.FC<{ email: string }> = ({ email, ...remainingProps }) => {
  if (!email && isBrowser) {
    navigate("/check-registration")
  }
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)
  const { login, isAuthenticated } = useAuth()
  const { state, dispatch }: any = useContext(AuthContext)
  const [user, setUser]= useState()

  const [form, setForm] = useState({
    email: "",
    password: "",
  })

  useEffect(() => {
    setForm({
      ...form,
      email: email || "",
    })
  }, [])

  const updateForm = (e: any) => {
    if (e.target.name == "email") {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      })
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      })
    }
  }


  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setIsSubmitting(true)
    setError(null)
    setForm({
      ...form,
      email: form.email?.toLowerCase(),
    })
    try {
      // Avoid failed builds due to window not existing
      const loggedIn = await login(form)
      if (loggedIn) {
        document.body.style.overflow = "scroll"
      }
    } catch (e) {
      setIsSubmitting(false)
      setError("Incorrect email or password. Please try again.")
    }
  }

  const handleReset = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    AnalyticsEventsClient.event({
      eventType: "click",
      eventName: "click_password_reset",
      eventMessage: "Store email address (If entered) and button click value ",
    })

    fetch(
      `${
        process.env.GATSBY_API_ROOT_URL
      }/auth/send-password-reset-email?Email=${encodeURIComponent(
        form.email?.toLowerCase()
      )}`,
      {
        method: "GET",
        headers: {
          accept: "*/*",
          authorization: `Bearer ${state?.token}`,
        },
      }
    )
      .then(data => {
        navigate("/reset-password", {
          state: {
            email: form.email,
          },
        })
      })
      .catch(error => {
        console.error(error)
      })
  }
  return (
    <form
      {...remainingProps}
      css={[tw`lg:mx-auto px-36 py-16 bg-red-300 lg:max-w-4xl z-50`]}
      onSubmit={handleSubmit}
    >
      <h5 css={[tw`text-white text-center`]}>Welcome back to ALEX!</h5>
      <div
        className="input-container"
        css={[tw`relative mx-auto flex flex-col mt-12 w-72`]}
      >
        <input
          className="input"
          type="email"
          placeholder="Email Address"
          value={form.email}
          name="email"
          onChange={updateForm}
          required
        />
        <label htmlFor="email">Email Address</label>
      </div>
      <div
        className="input-container"
        css={[tw`relative mx-auto flex flex-col mt-5 mb-8 w-72`]}
      >
        <input
          className="input"
          type="password"
          placeholder="password"
          value={form.password}
          name="password"
          onChange={updateForm}
          required
        />
        <label htmlFor="password">Password</label>
        {error && <p css={[tw`text-white text-center mt-4`]}>{error}</p>}
      </div>
      <ButtonGroup
        buttons={[
          {
            value: "Login",
            onClick: handleSubmit,
            dark: true,
            disabled: isSubmitting,
            loading: isSubmitting,
            eventName: "click_login",
            eventMessage: "Store email address and button click value",
          },
          {
            value: "Cancel",
            to: "/",
            secondary: true,
            eventName: "click_login_cancel",
            eventMessage: "Store email address and button click value",
          },
        ]}
      />
      <span
        onClick={handleReset}
        css={[
          tw`flex flex-row content-center items-center justify-center mt-5 cursor-pointer hover:scale-105`,
        ]}
      >
        <p css={[tw`text-sm text-white px-4`]}>Reset Password</p>
        <UpArrow css={[tw`rotate-90 text-white h-4`]} />
      </span>
    </form>
  )
}

export default Form
