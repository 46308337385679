import tw from "twin.macro"
import Form from "./Form"
import * as React from "react"


const Mobile: React.FC<{ email: string }> = ({ email }) => {
  return (
    <div css={[tw`visible lg:hidden`]}>
      <Form
        email={email}
        css={[
          tw`py-8 px-5 bg-red-300 flex flex-col fixed z-50 bottom-0 w-full`,
        ]}
      />
    </div>
  )
}

export default Mobile
